import { Injectable } from "@angular/core";
import { AlertController } from "@ionic/angular";
import { Geolocation, PermissionStatus, GeolocationPluginPermissions, Position } from "@capacitor/geolocation";
import { AndroidSettings, IOSSettings, NativeSettings } from "capacitor-native-settings";
import { GlobalsService } from "@services";


@Injectable()
export class GeolocationService {
	constructor(private alertCtrl: AlertController) {
	}

	async getGeolocation(): Promise<Position> {
		return new Promise<Position>(async (resolve) => {
			if (GlobalsService.isDesktop) {
				resolve(await Geolocation.getCurrentPosition());
				return;
			}

			let permission: PermissionStatus = await Geolocation.checkPermissions();

			if (permission.location === 'prompt') {
				await Geolocation.requestPermissions();
				permission = await Geolocation.checkPermissions();
			}

			if (permission.location !== "granted") {

				let alert = await this.alertCtrl.create({

					header: 'Permission denied',
					message: 'Location services access has been denied by the user.  Please allow location services permissions on your device.',
					buttons: [{
						text: 'Dismiss',
						handler: () => {
							if (GlobalsService.isAndroid) {
								NativeSettings.openAndroid({
									option: AndroidSettings.ApplicationDetails,
								});
							}
							else if (GlobalsService.isiOS) {
								NativeSettings.openIOS({
									option: IOSSettings.App
								});
							}
			
						}
					}]
				});
				alert.present(); 

				return null;
			}

			const geolocation = await Geolocation.getCurrentPosition(); 

			resolve(geolocation);
		});
	}
}