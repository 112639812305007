import { Component, OnInit } from '@angular/core';
import { TimeclockBreakOverlayStore } from "./timeclock-break-overlay.store";
import { TimeclockService } from "@services";
import { ITimeclockEntryModel } from '@models';
import * as moment from "moment";
import { SlickScreenBlockerService } from 'slick-components';
import { GeolocationService } from '@components/geolocation';

@Component({
	selector: 'timeclock-break-overlay',
	templateUrl: './timeclock-break-overlay.html',
	styleUrls: ['./timeclock-break-overlay.css'],
	providers: [TimeclockService]
})
export class TimeclockBreakOverlay implements OnInit {
	showOverlay: boolean = false;
	timeclockEntry: ITimeclockEntryModel;
	breakInterval: NodeJS.Timeout;
	breakEndsTimer: NodeJS.Timeout;
	breakDisplay: string;

	constructor(private timeclockBreakOverlayStore: TimeclockBreakOverlayStore,
		private timeclockService: TimeclockService,
		private geolocationService: GeolocationService,
		private slickScreenBlockerService: SlickScreenBlockerService) {
	}

	ngOnInit() {
		this.timeclockBreakOverlayStore.showOverlayStore.subscribe(showOverlay => {
			this.showOverlay = showOverlay;

			if (this.showOverlay === false)
				clearTimeout(this.breakInterval);
		});

		this.timeclockBreakOverlayStore.timeclockEntryStore.subscribe(timeclockEntry => {
			clearTimeout(this.breakInterval);

			this.timeclockEntry = timeclockEntry;

			if (!this.timeclockEntry)
				return;

			if (this.timeclockEntry.isOnBreak) {
				let breakEnd = moment(this.timeclockEntry.currentBreak.breakEnd);

				const breakDuration = moment.duration(breakEnd.diff(moment())).asMilliseconds();
				this.breakDisplay = moment.utc(breakDuration).format("m:ss");

				this.breakInterval = setInterval(() => {
					const breakDuration = moment.duration(breakEnd.diff(moment())).asMilliseconds();
					this.breakDisplay = moment.utc(breakDuration).format("m:ss");
				}, 1000)

				const breakRemainingMS = moment.duration(breakEnd.diff(moment())).asMilliseconds() - 1000;
				this.breakEndsTimer = setTimeout(() => {
					clearTimeout(this.breakInterval);
					this.endBreak();
				}, breakRemainingMS);
			}
		});

	}

	async endBreak() {
		try {
			const breakEndCoords = (await this.geolocationService.getGeolocation()).coords;
			if (!breakEndCoords)
				return;

			this.slickScreenBlockerService.block();
			// Refresh the timeclock entry from the database to make sure they aren't already on a break
			this.timeclockEntry = await this.timeclockService.getTimeclockEntry(this.timeclockEntry.timeclockEntryId);

			if (!this.timeclockEntry.isOnBreak) {
				clearTimeout(this.breakInterval);
				this.showOverlay = false;
				this.timeclockBreakOverlayStore.timeclockEntry = this.timeclockEntry;
				return;
			}

			this.timeclockEntry.currentBreak.breakEnd = new Date();
			this.timeclockEntry.currentBreak.breakEndGeolocation = this.timeclockService.getCoordString(breakEndCoords);;

			await this.timeclockService.endBreak(this.timeclockEntry.currentBreak);

			clearTimeout(this.breakInterval);
			this.showOverlay = false;
			// This will call the subscription in the appt or the timeclock to refresh the timeclock entry
			this.timeclockBreakOverlayStore.timeclockEntry = await this.timeclockService.getTimeclockEntry(this.timeclockEntry.timeclockEntryId);
		}
		finally {
			this.slickScreenBlockerService.unblock();
		}
	}

	hideOverlay() {
		clearTimeout(this.breakInterval);
		this.showOverlay = false;

		this.timeclockBreakOverlayStore.timeclockEntry = this.timeclockEntry;
	}

}
