export interface IMobileCustomMenuModel {
	menuName: string;
	displayName: string;
	icon: string;
	tab: string;
	visible: boolean;
	sortOrder: number;
}

export class MobileCustomMenuModel implements IMobileCustomMenuModel {
	constructor(menuName: string, displayName: string, icon: string, tab: string, sortOrder: number, visible: boolean) {
		this.menuName = menuName;
		this.displayName = displayName;
		this.icon = icon;
		this.tab = tab;
		this.sortOrder = sortOrder;
		this.visible = visible;
	}

	menuName: string;
	displayName: string;
	icon: string;
	tab: string;
	visible: boolean;
	sortOrder: number;
}