import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ITimeclockEntryModel } from "@models";

@Injectable()
export class TimeclockBreakOverlayStore {
	private static _showOverlay: boolean;
	private static _timeclockEntry: ITimeclockEntryModel;

	//*****************************************************************************
	//* showOverlay
	//*****************************************************************************
	private showOverlaySubject: Subject<boolean> = new Subject<boolean>();
	get showOverlay(): boolean {
		return TimeclockBreakOverlayStore._showOverlay;
	}

	set showOverlay(showOverlay: boolean) {
		TimeclockBreakOverlayStore._showOverlay = showOverlay;
		this.showOverlaySubject.next(showOverlay);
	}

	get showOverlayStore(): Subject<boolean> {
		return this.showOverlaySubject;
	}

	//*****************************************************************************
	//* timeclockEntry
	//*****************************************************************************
	private timeclockEntrySubject: Subject<ITimeclockEntryModel> = new Subject<ITimeclockEntryModel>();
	get timeclockEntry(): ITimeclockEntryModel {
		return TimeclockBreakOverlayStore._timeclockEntry;
	}

	set timeclockEntry(timeclockEntry: ITimeclockEntryModel) {
		TimeclockBreakOverlayStore._timeclockEntry = timeclockEntry;
		this.timeclockEntrySubject.next(timeclockEntry);
	}

	get timeclockEntryStore(): Subject<ITimeclockEntryModel> {
		return this.timeclockEntrySubject;
	}

}