import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMobileCustomMenuModel } from '@models';
import { MobileCustomButtonService } from "../services/mobile-custom-menu.service";
import { GlobalsService } from '../services';

@Injectable()
export class MobileMenuStore {
	private static _userCustomMenuItems: IMobileCustomMenuModel[] = [];

	userMenu$: BehaviorSubject<IMobileCustomMenuModel[]>;

	constructor(private readonly mobileCustomButtonService: MobileCustomButtonService) {
	}

	init() {
		MobileMenuStore._userCustomMenuItems = this.mobileCustomButtonService.getMenuForCurrentUser();
		this.userMenu$ = new BehaviorSubject<IMobileCustomMenuModel[]>(MobileMenuStore._userCustomMenuItems)
		this.userMenu$.next(MobileMenuStore._userCustomMenuItems);
	}

	getCustomMenuItems(): IMobileCustomMenuModel[] {
		return [...MobileMenuStore._userCustomMenuItems];
	}

	async saveCustomMenuItems(userCustomMenuItems: IMobileCustomMenuModel[]): Promise<IMobileCustomMenuModel[]> {
		const userCustomMenuItemsJSON = JSON.stringify(userCustomMenuItems);
		await this.mobileCustomButtonService.saveCustomMenuItems(userCustomMenuItemsJSON);
		GlobalsService.userSettings.customMobileMenu = userCustomMenuItemsJSON;
		MobileMenuStore._userCustomMenuItems = [...userCustomMenuItems];
		this.userMenu$.next(MobileMenuStore._userCustomMenuItems);

		return MobileMenuStore._userCustomMenuItems;
	}
}