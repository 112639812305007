<div *ngIf="showOverlay" class="timeclock-break-overlay bg-dark">
	<div class="d-flex flex-column align-items-center" style="margin-top: 80px">
		<div class="mb-4 text-white" style="font-size: 32px; text-align: center;">
			Break Remaining<br />
			{{breakDisplay}}
		</div>
		<div class="timeclock-break-return-button btn btn-danger" (click)="endBreak()">End Break</div>
	</div>

	<div class="timeclock-break-hide btn btn-info" (click)="hideOverlay()">Hide</div>
</div>