import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';

import { AtatusErrorHandler } from './atatus.handler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DevTraceService } from "@services/utils/dev-trace.service";
import { MobileCustomButtonService } from "@services/mobile-custom-menu.service";

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './http.interceptor';

import { TextChatHubService } from '../../../libraries/signalr/text-chat-hub.service';
import { MessageQueueDispatcher, MessageQueueHubService } from "@message-queue"; 

import { StoresModule, PushNotificationStore, MobileMenuStore, TasksStore } from "@stores";
import { HttpErrorDialogComponent } from "./http-error-dialog.component";
import { TimeclockBreakOverlayModule } from "./timeclock/timeclock-break-overlay/timeclock-break-overlay.module";
import { VersionReleaseDialogModule } from "@shared-components/version-release-dialog"

import { SlickDialogModule, SlickToastModule } from "slick-components";
import { GlobalsService, LookupService, AuthService, ConcurrencyValidationService, HttpService, FunctionLockService, QuickbooksService, JobsService, CustomersService, ContactsService, EquipmentService, NavPageService, SleepService, TasksService } from "@services";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { environment } from '../environments/environment';

@NgModule({
	declarations: [
		AppComponent,
		HttpErrorDialogComponent

	],
	imports: [
		BrowserModule,
		HttpClientModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		StoresModule,
		SlickDialogModule, SlickToastModule,
		TimeclockBreakOverlayModule,
		VersionReleaseDialogModule],
	providers: [
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		GlobalsService,
		DevTraceService,
		MobileCustomButtonService,
		TasksService,
		PushNotificationStore, MobileMenuStore,
		TextChatHubService, MessageQueueDispatcher, MessageQueueHubService,
		AuthService, HttpService, LookupService, DevTraceService, FunctionLockService, QuickbooksService, EquipmentService, NavPageService,
		JobsService, CustomersService, ContactsService,
		ConcurrencyValidationService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: ErrorHandler,
			useClass: AtatusErrorHandler
		},
		{
			provide: APP_INITIALIZER,
			useFactory: init,
			deps: [GlobalsService, PushNotificationStore, MessageQueueHubService, Platform],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function init(globalsService: GlobalsService, pushNotificationStore: PushNotificationStore, messageQueueHubService: MessageQueueHubService, platform: Platform) {
	return async () => {
		try {
			if (environment.staging === true)
				globalsService.setStaging();
			else if (environment.rc === true)
				globalsService.setRC();
			else if (environment.production === false)
				globalsService.setDebug();

			GlobalsService.isAndroid = platform.is("android");
			GlobalsService.isiOS = platform.is("ios");
			GlobalsService.isDesktop = platform.is('mobileweb') || platform.is('desktop');

			if (GlobalsService.isDesktop) {
				GlobalsService.isAndroid = false;
				GlobalsService.isiOS = false;
			}


			await globalsService.isLoggedIn();

			if (GlobalsService.isLoggedIn) {

				await globalsService.getCacheChecksums();
				await globalsService.init(true);

				messageQueueHubService.init();

				if (environment.production && (!GlobalsService.isDesktop))
					await pushNotificationStore.init();
			}
		}
		finally { }
	}
}