import { Injectable } from "@angular/core";
import { IMobileCustomMenuModel, MobileCustomMenuModel } from "../models";
import { GlobalsService } from "@services";
import { HttpService } from "./utils/http.service";
import { sortBy } from "sort-by-typescript";

@Injectable()
export class MobileCustomButtonService {
	private userCustomMenuItems: IMobileCustomMenuModel[] = [];
	private isAdmin: boolean;
	private isEstimator: boolean;
	private isBusinessDevelopment: boolean;
	private isTech: boolean;

	private adminSortOrder: string[] = ['jobs', 'tasks', 'schedule', 'timeclock', 'equipment', 'receipts', 'business-development', 'mitigation', 'text-chat']
	private estimatorSortOrder: string[] = ['jobs', 'tasks', 'schedule', 'timeclock', 'equipment', 'receipts', 'business-development', 'mitigation', 'text-chat']
	private businessDevelopmentSortOrder: string[] = ['business-development', 'tasks', 'jobs', 'receipts', 'timeclock', 'text-chat']
	private techSortOrder: string[] = ['schedule', 'tasks', 'jobs', 'equipment', 'receipts', 'timeclock', 'mitigation', 'text-chat']

	constructor(private httpService: HttpService) {

	}

	getMenuForCurrentUser(): IMobileCustomMenuModel[] {
		if (GlobalsService.userSettings.customMobileMenu)
			this.userCustomMenuItems = JSON.parse(GlobalsService.userSettings.customMobileMenu);

		if (GlobalsService.userInfo.isAdmin)
			this.isAdmin = true;
		else if (GlobalsService.userInfo.isEstimator)
			this.isEstimator = true;
		else if (GlobalsService.userInfo.isBusinessDevelopment && !GlobalsService.userInfo.isEstimator)
			this.isBusinessDevelopment = true;
		else
			this.isTech = true;

		let userCustomMenuItems: IMobileCustomMenuModel[] = [];

		userCustomMenuItems.push(this.addMenuItem('jobs', 'Jobs', 'fad fa-tools'));
		userCustomMenuItems.push(this.addMenuItem('tasks', 'Tasks', 'far fa-list-check'));
		userCustomMenuItems.push(this.addMenuItem('schedule', 'Schedule', 'fad fa-calendar'));
		userCustomMenuItems.push(this.addMenuItem('timeclock', 'Timeclock', 'fad fa-user-clock'));
		userCustomMenuItems.push(this.addMenuItem('equipment', 'Equipment', 'fad fa-tag'));
		userCustomMenuItems.push(this.addMenuItem('receipts', 'Receipts', 'fad fa-receipt'));
		userCustomMenuItems.push(this.addMenuItem('business-development', 'Business Development', 'fad fa-handshake'));
		userCustomMenuItems.push(this.addMenuItem('mitigation', 'Mitigation', 'far fa-tint'));
		userCustomMenuItems.push(this.addMenuItem('text-chat', 'Text Chat', 'fad fa-comments-alt'));	

		userCustomMenuItems = userCustomMenuItems.filter(x => x !== null);
		userCustomMenuItems.sort(sortBy("sortOrder^"));

		return userCustomMenuItems;

	}

	async saveCustomMenuItems(userCustomMenuItemsJSON: string): Promise<void> {
		return this.httpService.post('/userSettings/saveMobileCustomMenu', userCustomMenuItemsJSON);
	}

	private addMenuItem(menuName: string, displayName: string, icon: string): IMobileCustomMenuModel  {

		const userCustomMenu = this.userCustomMenuItems.find(x => x.menuName === menuName);
		if (userCustomMenu)
			return userCustomMenu;
		else {

			let sortOrder: number = this.getSortOrder(menuName);
			let visible: boolean = sortOrder >= 0;
			// Techs can only access what is on their menu options
			if (this.isTech && sortOrder < 0)
				return null;

			return new MobileCustomMenuModel(menuName, displayName, icon, menuName, sortOrder, visible);
		}
	}

	private getSortOrder(menuName: string): number {
		let idx = -1;

		if (this.isAdmin)
			idx = this.adminSortOrder.findIndex(x => x === menuName);
		else if (this.isEstimator)
			idx = this.estimatorSortOrder.findIndex(x => x === menuName);
		else if (this.isBusinessDevelopment)
			idx = this.businessDevelopmentSortOrder.findIndex(x => x === menuName);
		else
			idx = this.techSortOrder.findIndex(x => x === menuName);

		return idx;
	}
}
