import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SlickScreenBlockerModule } from "slick-components";

import { TimeclockBreakOverlay } from './timeclock-break-overlay';
import { TimeclockBreakOverlayStore } from "./timeclock-break-overlay.store";
import { GeolocationModule } from "@components/geolocation";

@NgModule({
	imports: [
		CommonModule,
		SlickScreenBlockerModule,
		GeolocationModule
	],
	declarations: [TimeclockBreakOverlay],
	exports: [TimeclockBreakOverlay],
	providers: [TimeclockBreakOverlayStore]
})
export class TimeclockBreakOverlayModule { }
