import { HttpInterceptor, HttpClient, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { concatMap, delay, retryWhen } from 'rxjs/operators';
import { GlobalsService } from "@services"
import { HttpErrorDialogService } from "@services/utils/http-error-dialog.service";
import * as moment from 'moment';

class ExceptionLogModel {
	exceptionLogId: number;
	userName: string;
	application: string;
	dateTime: string;
	url: string;
	statusCode?: number;
	message: string;
	stackTrace: string;
	jsonModel: string;
	fullErrorObj: string;
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private httpClient: HttpClient) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Make sure the url doesn't already have the apiUrl as part of it
		let newUrl = request.url.replace(GlobalsService.apiUrl, '');
		// We need to add the prefix depending on if we're dev or prod
		newUrl = (newUrl.indexOf('/') === 0) ? (GlobalsService.apiUrl + newUrl) : (GlobalsService.apiUrl + '/' + newUrl);
		const newRequest = new HttpRequest(<any>request.method, newUrl, <any>request.responseType);

		let headers;

		if (request.headers.has("Content-Type") && request.headers.get("Content-Type") === "multipart/form-data") {
			headers = request.headers
				.set("Authorization", (GlobalsService.jwtToken) ? ("Bearer " + GlobalsService.jwtToken) : "")
				.delete('Content-Type')
				.set("TimeZoneOffset", (moment().utcOffset() / 60).toString());
		} else {
			headers = request.headers
				.set("Authorization", (GlobalsService.jwtToken) ? ("Bearer " + GlobalsService.jwtToken) : "")
				.set("Content-Type", "application/json")
				.set("TimeZoneOffset", (moment().utcOffset() / 60).toString());
		}

		const cloneReq = newRequest.clone({ headers, body: request.body, params: request.params });

		return next.handle(cloneReq).pipe(
			retryWhen(error =>
				error.pipe(
					concatMap((error, count) => {
						console.error(error);
						const internalError = error.error || {};
						const message = <string>internalError.exceptionMessage || JSON.stringify(internalError);

						if (JSON.stringify(message).indexOf('Company has been deactivated') > 0) {
							GlobalsService.jwtToken = null;
							GlobalsService.isLoggedIn = false;
							localStorage.removeItem("jwt_token");
							alert("This company has been deactivated");
							window.location.href = "/";
							return;
						}

						if (JSON.stringify(message).indexOf('Account has been deactivated') > 0) {
							GlobalsService.jwtToken = null;
							GlobalsService.isLoggedIn = false;
							localStorage.removeItem("jwt_token");
							alert("This account has been deactivated.  Contact your administrator.");
							window.location.href = "/";
							return;
						}

						if (count < 5 &&
							(error.statusText == 'Unknown Error' ||
								message.startsWith("An exception has been raised that is likely due to a transient failure.") === true ||
								message.indexOf("was deadlocked on lock resources with another process and has been chosen as the deadlock victim. Rerun the transaction.") > 0)) {
							return of(error);
						}

						// If saving to the exception log failed, don't try again!
						if ((request.url.indexOf("saveExceptionLog") < 0) &&
							(request.url.indexOf('jobs/uploadPhoto') < 0) &&
							(request.url.indexOf('test/ping') < 0)) {
							try {
								const body = JSON.stringify(request.body);
								const url = request.urlWithParams;
								const exceptionLogModel = new ExceptionLogModel();
								if (GlobalsService.userInfo)
									exceptionLogModel.userName = GlobalsService.userInfo.fullName;
								exceptionLogModel.application = "JobSight Mobile";
								exceptionLogModel.url = url;
								exceptionLogModel.statusCode = error.status;
								exceptionLogModel.message = message;
								exceptionLogModel.stackTrace = internalError.stackTrace || "";
								exceptionLogModel.jsonModel = body;
								exceptionLogModel.fullErrorObj = JSON.stringify(error);

								this.httpClient.post("/exceptionLogs/saveExceptionLog", exceptionLogModel).toPromise();

								if (HttpErrorDialogService.errorDialogComponentCallback)
									(<any>HttpErrorDialogService.errorDialogComponentCallback).showHttpError(error, url, body);

							}
							catch { }
						}

						return throwError(error);
					}),
					delay(1000)
				)
			)
		);
	}

}