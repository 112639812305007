import { Injectable } from '@angular/core';
import { GlobalsService, HttpService, NavPageService } from '@services';


import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	Token,
} from '@capacitor/push-notifications';

@Injectable()
export class PushNotificationStore {
	constructor(private httpService: HttpService) {
	}

	async init(): Promise<void> {
		PushNotifications.addListener('registration', async (token: Token) => {
			GlobalsService.pushNotificationToken = token.value;
			console.info(`Push Notification Connected for user ${GlobalsService.userInfo.fullName}`);
			console.info(`Push Notification Token: ${token.value}`);
		});

		PushNotifications.addListener('registrationError', async (error: any) => {
			alert('Error on registration: ' + JSON.stringify(error));
		});

		PushNotifications.addListener(
			'pushNotificationReceived',
			async (notification: PushNotificationSchema) => {
				console.info('Push received: ' + JSON.stringify(notification));
			},
		);

		PushNotifications.addListener(
			'pushNotificationActionPerformed',
			async (notification: ActionPerformed) => {
				console.info('Push action received: ' + JSON.stringify(notification));
				if (notification.notification.data.ConversationId) {
					GlobalsService.initialPage = `text-chat-messages/${notification.notification.data.ConversationId}`;
					//this.navPageService.push(`text-chat-messages/${notification.notification.data.ConversationId}`);				
				}

				if (notification.notification.data.WorkOrderId) {
					GlobalsService.initialPage = `work-order-view/${notification.notification.data.WorkOrderId}`;
					//this.navPageService.push(`work-order-edit/${notification.notification.data.WorkOrderId}`);				
				}

				if (notification.notification.data.TaskId) {
					GlobalsService.initialPage = `task-edit/${notification.notification.data.TaskId}`;
					//this.navPageService.push(`work-order-edit/${notification.notification.data.WorkOrderId}`);				
				}
			},
		);

		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		const result = await PushNotifications.requestPermissions();

		if (result.receive === 'granted') {
			// Register with Apple / Google to receive push via APNS/FCM
			await PushNotifications.register();
		} else {
			// Show some error
		}
	}
}
