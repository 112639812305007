import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{ path: '', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
	{ path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsPageModule) },
	{ path: 'appointment-edit/:appointmentId', loadChildren: () => import('./appointments/appointment-edit/appointment-edit.module').then(m => m.AppointmentEditPageModule) },
	{ path: 'appointment-edit/:appointmentId/:currentDate', loadChildren: () => import('./appointments/appointment-edit/appointment-edit.module').then(m => m.AppointmentEditPageModule) },
	{ path: 'appointment-view/:appointmentId/:canSeeTimeclock/:selectedEmployeeUserName', loadChildren: () => import('./appointments/appointment-view/appointment-view.module').then(m => m.AppointmentViewPageModule) },
	{ path: 'default', loadChildren: () => import('./default/default.module').then(m => m.DefaultPageModule) },
	{ path: 'certifications', loadChildren: () => import('./certifications/certifications.module').then(m => m.CertificationsPageModule) },
	{ path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsPageModule) },
	{ path: 'customer-edit/:customerId', loadChildren: () => import('./customers/customer-edit/customer-edit.module').then(m => m.CustomerEditPageModule) },
	{ path: 'emailer', loadChildren: () => import('./emails/emailer/emailer.module').then(m => m.EmailerPageModule) },
	{ path: 'equipment-edit/:equipmentId', loadChildren: () => import('./equipment/equipment-edit/equipment-edit.module').then(m => m.EquipmentEditPageModule) },
	{ path: 'job-add-equipment/:jobId', loadChildren: () => import('./jobs/job-add-equipment/job-add-equipment.module').then(m => m.JobAddEquipmentPageModule) },
	{ path: 'job-add-equipment/:jobId/:mitigationRoomId', loadChildren: () => import('./jobs/job-add-equipment/job-add-equipment.module').then(m => m.JobAddEquipmentPageModule) },
	{ path: 'job-add-inventory/:jobId', loadChildren: () => import('./jobs/job-add-inventory/job-add-inventory.module').then(m => m.JobAddInventoryPageModule) },
	{ path: 'job-edit/:jobId', loadChildren: () => import('./jobs/job-edit/job-edit.module').then(m => m.JobEditPageModule) },
	{ path: 'job-list-settings', loadChildren: () => import('./jobs/jobs-list-settings/jobs-list-settings.module').then(m => m.JobsListSettingsPageModule) },
	{ path: 'job-view/:jobId/:action', loadChildren: () => import('./jobs/job-view/job-view.module').then(m => m.JobViewPageModule) },
	{ path: 'job-sign-agreements/:serviceAgreementUuid/:jobId', loadChildren: () => import('./jobs/job-sign-agreements/job-sign-agreements.module').then(m => m.JobSignAgreementsPageModule) },
	{ path: 'job-confirm-signature/:serviceAgreementUuid', loadChildren: () => import('./jobs/job-confirm-signature/job-confirm-signature.module').then(m => m.JobConfirmSignaturePageModule) },
	{ path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
	{ path: 'forgot-password', loadChildren: () => import('./login/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
	{ path: 'reset-password/:token/:authCode', loadChildren: () => import('./login/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
	{ path: 'mitigation-atmospheric-reading-edit/:mitigationRoomId/:mitigationAtmosphericReadingId', loadChildren: () => import('./mitigation/mitigation-atmospheric-reading-edit/mitigation-atmospheric-reading-edit.module').then(m => m.MitigationAtmosphericReadingEditPageModule) },
	{ path: 'mitigation-plan/:jobId', loadChildren: () => import('./mitigation/mitigation-plan/mitigation-plan.module').then(m => m.MitigationPlanPageModule) },
	{ path: 'mitigation-room-edit/:jobId/:mitigationPlanId/:mitigationRoomId', loadChildren: () => import('./mitigation/mitigation-room-edit/mitigation-room-edit.module').then(m => m.MitigationRoomEditPageModule) },
	{ path: 'mitigation-room-design/:mitigationRoomId', loadChildren: () => import('./mitigation/mitigation-room-design/mitigation-room-design.module').then(m => m.MitigationRoomDesignPageModule) },
	{ path: 'mitigation-structure-reading-edit/:mitigationRoomStructureId/:mitigationRoomStructureReadingId', loadChildren: () => import('./mitigation/mitigation-structure-reading-edit/mitigation-structure-reading-edit.module').then(m => m.MitigationStructureReadingEditPageModule) },
	{ path: 'photo-upload-detail/:uuid', loadChildren: () => import('./photo-uploads/photo-upload-detail/photo-upload-detail.module').then(m => m.PhotoUploadDetailPageModule) },
	{ path: 'photo-uploads-list', loadChildren: () => import('./photo-uploads/photo-uploads-list/photo-uploads-list.module').then(m => m.PhotoUploadsListPageModule) },
	{ path: 'business-development-account-view/:businessDevelopmentAccountId', loadChildren: () => import('./business-development/business-development-account-view/business-development-account-view.module').then(m => m.BusinessDevelopmentAccountViewPageModule) },
	{ path: 'business-development-contact-view/:businessDevelopmentAccountId/:businessDevelopmentContactId', loadChildren: () => import('./business-development/business-development-contact-view/business-development-contact-view.module').then(m => m.BusinessDevelopmentContactViewPageModule) },
	{ path: 'business-development-contact-edit/:businessDevelopmentAccountId/:businessDevelopmentContactId', loadChildren: () => import('./business-development/business-development-contact-edit/business-development-contact-edit.module').then(m => m.BusinessDevelopmentContactEditPageModule) },
	{ path: 'business-development-list-settings', loadChildren: () => import('./business-development/business-development-list-settings/business-development-list-settings.module').then(m => m.BusinessDevelopmentListSettingsPageModule) },
	{ path: 'reporting', loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingPageModule) },
	{ path: 'reporting/survey-report', loadChildren: () => import('./reporting/reports/survey-report/survey-report.module').then(m => m.SurveyReportPageModule) },
	{ path: 'test', loadChildren: () => import('./test/test.module').then(m => m.TestPageModule) },
	{ path: 'customize-menu', loadChildren: () => import('./customize-menu/customize-menu.module').then(m => m.CustomizeMenuModule) },
	{ path: 'task-edit/:taskId', loadChildren: () => import('./tasks/task-edit/task-edit.module').then(m => m.TaskEditPageModule) },
	{ path: 'timeclock-clock-out/:timeclockEntryId', loadChildren: () => import('./timeclock/timeclock-clock-out/timeclock-clock-out.module').then(m => m.TimeclockClockOutPageModule) },
	{ path: 'timeclock-switch-jobs/:timeclockEntryId', loadChildren: () => import('./timeclock/timeclock-switch-jobs/timeclock-switch-jobs.module').then(m => m.TimeclockSwitchJobsPageModule) },
	{ path: 'timeclock-switch-jobs/:timeclockEntryId/:jobId', loadChildren: () => import('./timeclock/timeclock-switch-jobs/timeclock-switch-jobs.module').then(m => m.TimeclockSwitchJobsPageModule) },
	{ path: 'time-off-request', loadChildren: () => import('./timeclock/time-off-request/time-off-request.module').then(m => m.TimeOffRequestPageModule) },
	{ path: 'text-chat-conversations', loadChildren: () => import('./text-chat/pages/text-chat-conversations/text-chat-conversations.module').then(m => m.TextChatConversationsPageModule) },
	{ path: 'text-chat-messages/:textChatConversationId', loadChildren: () => import('./text-chat/pages/text-chat-messages/text-chat-messages.module').then(m => m.TextChatMessagesPageModule) },
	{ path: 'work-orders-list', loadChildren: () => import('./work-orders/pages/work-orders-list/work-orders-list.module').then(m => m.WorkOrdersListPageModule) },
	{ path: 'work-order-edit/:workOrderId', loadChildren: () => import('./work-orders/pages/work-order-edit/work-order-edit.module').then(m => m.WorkOrderEditPageModule) },
];
@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
