import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, MenuController, LoadingController } from "@ionic/angular";
import { Router, NavigationStart } from '@angular/router';
import { GlobalsService, AuthService, LoginService, TimeclockService, JobsService, LookupService, SleepService, HttpService } from "@services";
import { DevTraceService } from "@services/utils/dev-trace.service";
import { SlickInitService, SlickInitParams } from "slick-components";
import { TimeclockBreakOverlayStore } from "@app/timeclock/timeclock-break-overlay/timeclock-break-overlay.store";
import { PhotoUploadStore, TextChatStore, MobileMenuStore, TasksStore } from "@stores";
import { TextChatHubService } from '../../../libraries/signalr/text-chat-hub.service';
import { VersionReleaseDialogComponent } from "@shared-components/version-release-dialog"

import { Platform } from '@ionic/angular';

// Capacitor
import { Deploy } from 'cordova-plugin-ionic';
import { SplashScreen } from '@capacitor/splash-screen';
import { Network, ConnectionStatus } from '@capacitor/network';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	providers: [LoginService, JobsService, TimeclockService]
})
export class AppComponent implements OnInit {
	@ViewChild("dialogRef", { static: true }) dialogRef: VersionReleaseDialogComponent;

	showTest: boolean = false;
	clickCount: number = 0;
	isReady: boolean = false;
	isLoggedIn: boolean = false;
	isSubcontractor: boolean;
	userName: string;
	userId: number = 0;
	firstName: string;
	profilePictureUrl: string;
	showOverlay: boolean;
	osVersion: string;
	deployChannel = "";
	currentBuildId = '';
	version = GlobalsService.appVersion;
	downloading: boolean = false;
	downloadPercent: number = 0;
	slickComponentsVersion: string;

	lastTarget: HTMLElement;
	preventDoubleClickTimeout: NodeJS.Timeout;

	constructor(
		private platform: Platform,
		private navCtrl: NavController,
		private menuCtrl: MenuController,
		private router: Router,
		private authService: AuthService,
		private loginService: LoginService,
		private loadingCtrl: LoadingController,
		private globalsService: GlobalsService,
		private lookupService: LookupService,
		private devTrace: DevTraceService,
		private timeclockService: TimeclockService,
		private timeclockBreakOverlayStore: TimeclockBreakOverlayStore,
		private photoUploadStore: PhotoUploadStore,
		private mobileMenuStore: MobileMenuStore,
		private tasksStore: TasksStore,
		private textChatStore: TextChatStore,
		private textChatHub: TextChatHubService,
		private httpService: HttpService
	) {		

		if (GlobalsService.isLoggedIn) {
			this.showTest = GlobalsService.userInfo.isGlobalAdmin;
			this.tasksStore.init(true);
		}

		// This code will trap all buttons and prevent the user from double clicking
		document.addEventListener('click', (e: MouseEvent) => {
			let buttonEl: HTMLButtonElement;

			// Go 3 elements deep to see if this is a button.  
			// The first element is most likely going to be the text of the button
			const target = <HTMLElement>e.target;
			if (target.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>target;

			const parentTarget = <HTMLElement>((target === null) ? null : target.parentElement);
			if (parentTarget && parentTarget.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>parentTarget;

			const grandparentTarget = <HTMLElement>((parentTarget === null) ? null : parentTarget.parentElement);
			if (grandparentTarget && grandparentTarget.nodeName === 'BUTTON')
				buttonEl = <HTMLButtonElement>grandparentTarget;

			if (buttonEl) {
				if (buttonEl.hasAttribute("disabled") ||
					buttonEl.hasAttribute("allowDoubleClick"))
					return;

				setTimeout(() => {
					if (buttonEl.querySelector(".slick-button-spinner_error"))
						return;

					buttonEl.setAttribute("disabled", 'disabled');
				});

				// If this has a slick-button-spinner, let the spinner re-enable
				if (!buttonEl.classList.contains("slick-button-spinner"))
					setTimeout(() => buttonEl.removeAttribute("disabled"), 600);
			}

		});
	}

	async ngOnInit() {
		await this.platform.ready();


		GlobalsService.isOnline = (await Network.getStatus()).connected;
		Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
			GlobalsService.isOnline = status.connected;
			if (GlobalsService.isOnline) {
				this.devTrace.addTrace(`network status change: ${JSON.stringify(status)}`);
			}
		});

		console.info('Initializing HomePage');


		this.devTrace.addTrace(`url: ${document.URL}`);
		// If we are simulating an iPhone in the browser, ios will be true
		//if (GlobalsService.isDebug || document.URL.indexOf("devmobile")) {
		//	GlobalsService.isAndroid = false;
		//	GlobalsService.isiOS = false;
		//	GlobalsService.isDesktop = true;
		//}

		this.devTrace.addTrace(`IsDebug: ${GlobalsService.isDebug}`);
		this.devTrace.addTrace(`IsAndroid: ${GlobalsService.isAndroid}`);
		this.devTrace.addTrace(`isiOS: ${GlobalsService.isiOS}`);
		if (!GlobalsService.isDebug && (GlobalsService.isAndroid || GlobalsService.isiOS)) {
			this.osVersion = "v.x rev.x";
			try {
				let res = await Deploy.getConfiguration();

				// Make sure we're looking at the right channel
				if (res.channel !== 'Beta') {
					await Deploy.configure({ channel: `Prod ${res.binaryVersionName}` });
					res = await Deploy.getConfiguration();
				}

				this.devTrace.addTrace(`channel: ${res.channel}`);
				this.devTrace.addTrace(`updateMethod: ${res.updateMethod}`);

				const appVersion = res.binaryVersionName;
				const revision = res.binaryVersionCode;
				
				this.devTrace.addTrace(`osVersion: v.${appVersion} rev.${revision}`);
				this.osVersion = `v.${appVersion} rev.${revision}`;

				this.deployChannel = res.channel;
				this.currentBuildId = res.currentBuildId;
				await Deploy.configure({ updateMethod: 'none' });
			}
			catch (err) {
			}

			setTimeout(async () => {
				try {
					this.devTrace.addTrace("Check for update");
					const updateResponse = await Deploy.checkForUpdate();
					this.devTrace.addTrace(`Check for update: ${updateResponse.available}`);
					if (updateResponse.available === true) {
						this.devTrace.addTrace(`Downloading Update`);

						const perfStart = performance.now();
						this.downloading = true;
						let lastPercent = 0;
						await Deploy.downloadUpdate(async (progress) => {
							if (progress !== lastPercent) {
								lastPercent = progress;
								this.downloadPercent = progress;

								this.devTrace.addTrace(`Downloading Update ${progress}%`);
							}
						});
						this.downloadPercent = 0;
						this.devTrace.addTrace(`Update downloaded in ${(performance.now() - perfStart).toFixed(3)}ms`);
						this.devTrace.addTrace(`Extracting`);
						await Deploy.extractUpdate((progress) => this.downloadPercent = progress)
						this.devTrace.addTrace(`Extracting Success`);
						this.devTrace.addTrace(`Complete`);
						Deploy.reloadApp();
					}
					else {
						this.devTrace.addTrace(`Complete`);
					}
				}
				catch (err) {
					this.devTrace.addTrace(`Error: ${JSON.stringify(err)}`);
					let exceptionLogModel: any = {};
					if (GlobalsService.userInfo)
						exceptionLogModel.userName = GlobalsService.userInfo.fullName || 'N/A';
					exceptionLogModel.application = "JobSight Mobile";
					exceptionLogModel.message = `Failed to update - ${this.downloadPercent}%`
					await this.httpService.post("/exceptionLogs/saveExceptionLog", exceptionLogModel);

					exceptionLogModel.message = JSON.stringify(err);
					await this.httpService.post("/exceptionLogs/saveExceptionLog", exceptionLogModel);
				}
			}, 5000);
		}

		this.isLoggedIn = await this.globalsService.isLoggedIn();
		if (this.isLoggedIn === false) {
			if (window.location.href.indexOf("/login") < 0) {
				this.devTrace.addTrace(window.location.href);
				this.devTrace.addTrace(`Redirecting to login page`);
				await this.router.navigateByUrl('/login');
				window.location.reload();
				return;
			}

			await SplashScreen.hide();
			this.isReady = true;

			return;
		}

		const loading = await this.loadingCtrl.create({
			message: `Initializing...`
		});

		const loadingTimer = setTimeout(async () => {
			SplashScreen.hide();
			await loading.present();
		}, 1000);

		//await this.globalsService.getCacheChecksums();
		//await this.globalsService.init(true);

		this.isSubcontractor = GlobalsService.userInfo.isSubcontractor;

		if (!this.isSubcontractor)
			this.mobileMenuStore.init();

		const slickInitParams = new SlickInitParams();

		slickInitParams.platform = this.platform.is("android") ? "android" : '';
		SlickInitService.init(slickInitParams);
		this.slickComponentsVersion = slickInitParams.version;

		await SplashScreen.hide();

		clearTimeout(loadingTimer);
		await loading.dismiss();

		this.userName = GlobalsService.userInfo.userName;
		this.firstName = GlobalsService.userInfo.firstName;
		this.userId = GlobalsService.userInfo.userId;
		this.profilePictureUrl = GlobalsService.userInfo.profilePictureUrl;

		try {
			this.devTrace.addTrace(`User ${this.userName} logged in. AppVersion: ${GlobalsService.appVersion}`);
		}
		catch { }

		if (!this.isSubcontractor) {
			this.timeclockBreakOverlayStore.timeclockEntry = await this.timeclockService.getLastTimeclockEntry(GlobalsService.userInfo.userId);
			if (this.timeclockBreakOverlayStore.timeclockEntry)
				this.timeclockBreakOverlayStore.showOverlay = (this.timeclockBreakOverlayStore.timeclockEntry.isOnBreak);

			if (GlobalsService.companySettings.useTextChat) {
				this.textChatHub.init();
				await this.textChatStore.init(true);
			}
		}

		this.isReady = true;

		if (GlobalsService.userInfo.lastSeenVersion != GlobalsService.appVersion) {
			if (!GlobalsService.userInfo.lastSeenVersion || GlobalsService.userInfo.lastSeenVersion === "0")
				await this.httpService.patch(`/versionRelease/updateUserLastSeenVersion?versionNumber=${GlobalsService.appVersion}`);
			else {
				await this.dialogRef.showDialog();
				await this.httpService.patch(`/versionRelease/updateUserLastSeenVersion?versionNumber=${GlobalsService.appVersion}`);
			}
		}

		let backgroundPhotoTimer: NodeJS.Timeout = setInterval(() => {
			this.devTrace.addTrace("Running Photo Upload 1")
			this.photoUploadStore.uploadPhotos();
		}, 30000)

		this.platform.resume.subscribe(async () => {
			clearInterval(backgroundPhotoTimer);

			this.devTrace.addTrace("Running Photo Upload 2")
			this.photoUploadStore.uploadPhotos();

			backgroundPhotoTimer = setInterval(() => {
				this.devTrace.addTrace("Running Photo Upload 3")
				this.photoUploadStore.uploadPhotos();
			}, 30000)
		});

		//this.devTrace.addTrace("Running Photo Upload Init")
		//this.photoUploadStore.uploadPhotos();

		setTimeout(() => {
			this.lookupService.clearLocalstorage();
		}, 5000);

		this.router.events.subscribe(async (event) => {
			if (event instanceof NavigationStart) {
				if (GlobalsService.isLoggedIn === true) {
					// check every hour to see if we're still active
					setInterval(async () => {
						try {
							const isActive = await this.globalsService.isLoggedIn();

							if (!isActive) {
								this.authService.clearToken();
								this.router.navigateByUrl('/login');
							}
						}
						catch (err) {
							console.error(err);
						}
					}, 3600000);
				}
				else {
					if (event.url.toLowerCase().indexOf('/login') < 0) {
						this.router.navigateByUrl('/login');
						return;
					}
				}
			}
		});
	}

	openPage(page) {
		this.menuCtrl.close();

		switch (page) {
			case "About Us":
				this.navCtrl.navigateForward("/about-us");
				break;

			case "Contact Us":
				this.navCtrl.navigateForward("/contact-us");
				break;

			case "Certifications":
				this.navCtrl.navigateForward("/certifications");
				break;

			case "Customers":
				this.navCtrl.navigateRoot("/tabs/customers");
				break;

			case "PhotosList":
				this.navCtrl.navigateForward("/photo-uploads-list");
				break;

			case "Reporting":
				this.navCtrl.navigateRoot("/tabs/reporting");
				break;

			case "CustomizeMenu":
				this.navCtrl.navigateForward("/customize-menu");
				break;

			case "Test":
				this.navCtrl.navigateForward("/test");
				break;
		}
	}

	async signOut() {
		this.menuCtrl.close();
		this.isLoggedIn = false;
		GlobalsService.isLoggedIn = false;
		this.loginService.logout("mobile");
		this.authService.clearToken();
		this.navCtrl.navigateRoot("login");
	}

	clickForTest() {
		this.clickCount++;

		if (this.clickCount >= 10)
			this.showTest = true;
	}
}
