import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { GlobalsService } from "@services";

import * as atatus from 'atatus-js';
atatus.config('065764ef124d49e2b9c11ac027d1302d', {
	version: GlobalsService.appVersion
}).install();

@Injectable()
class AtatusErrorHandler extends ErrorHandler {
	handleError(error: any): void {
		if (atatus && GlobalsService.isDebug === false) {
			// Send the error to Atatus
			const tags = ['mobile'];
			if (GlobalsService.userInfo)
				tags.push(GlobalsService.userInfo.userName);

			atatus.notify(error.originalError || error, null, tags);
		}

		console.error(error);
		//alert(error);
		throw error;
	}
}

export { AtatusErrorHandler };