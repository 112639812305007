<ion-app>
	<div *ngIf="isReady && isLoggedIn">
		<ng-container *ngTemplateOutlet="routerOutletTemplate"></ng-container>
	</div>

	<div *ngIf="isReady && !isLoggedIn">
		<ion-router-outlet id="content" main [swipeGesture]="false"></ion-router-outlet>
	</div>
</ion-app>

<http-error-dialog></http-error-dialog>
<timeclock-break-overlay></timeclock-break-overlay>

<ng-template #routerOutletTemplate>
	<ion-menu *ngIf="!isLoggedIn" contentId="content" side="start" type="overlay" swipeGesture="false">
	</ion-menu>
	<ion-menu *ngIf="isLoggedIn" contentId="content" side="start" type="overlay" swipeGesture="false">
		<ion-header>
			<ion-toolbar>
				<ion-title>JobSight</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content class="ion-padding">
			<div class="col-12 mt-3">
				<div style="line-height: 1.4rem; width: calc(100% - 60px); display: inline-block; padding-top: 2px;">
					<div (click)="clickForTest()">Logged in as {{firstName}}</div>
					OS Version {{osVersion}}<br />
					App Version {{version}}<br />
					Build: {{currentBuildId}}<br />
					<div *ngIf="downloading">
						Downloading: {{downloadPercent}}%<br />
					</div>
					<div *ngIf="userId === 1 || deployChannel === 'beta'">
						Channel: {{deployChannel}}<br />
						Slick Components: {{slickComponentsVersion}}<br />
					</div>
				</div>
				<div style="width: 50px; text-align: right; display: inline-block; vertical-align: top; ">
					<img *ngIf="profilePictureUrl" [src]="profilePictureUrl" class="profile-picture" />
				</div>
			</div>
			<ion-list *ngIf="!isSubcontractor">
				<ion-item-group>
					<ion-item *ngIf="showTest" (click)="openPage('Test')" menuClose>
						<i class="fad fa-tachometer-alt-fastest" style="width: 32px; text-align: center"></i>&nbsp;Test
					</ion-item>

					<ion-item (click)="openPage('Customers')" menuClose>
						<i class="fad fa-users" style="width: 32px; text-align: center"></i>&nbsp;Customers
					</ion-item>

					<ion-item (click)="openPage('PhotosList')" menuClose>
						<i class="fad fa-image" style="width: 32px; text-align: center"></i>&nbsp;Photos
					</ion-item>

					<ion-item (click)="openPage('Certifications')" menuClose>
						<i class="fad fa-award" style="width: 32px; text-align: center"></i>&nbsp;Certifications
					</ion-item>

					<ion-item (click)="openPage('Reporting')" menuClose>
						<i class="fad fa-chart-pie" style="width: 32px; text-align: center"></i>&nbsp;Reporting
					</ion-item>

					<ion-item (click)="openPage('CustomizeMenu')" menuClose>
						<i class="fad fa-ellipsis-h-alt" style="width: 32px; text-align: center"></i>&nbsp;Customize Menu
					</ion-item>

					<ion-item (click)="signOut()" menuClose>
						<i class="fad fa-sign-out" style="width: 32px; text-align: center"></i>&nbsp;Sign Out
					</ion-item>

				</ion-item-group>
			</ion-list>

			<ion-list *ngIf="isSubcontractor">
				<div style="min-height: calc(100vh - 280px)"></div>
				<ion-item-group>
					<ion-item (click)="signOut()" menuClose>
						<i class="fad fa-sign-out" style="width: 32px; text-align: center"></i>&nbsp;Sign Out
					</ion-item>

				</ion-item-group>
			</ion-list>
		</ion-content>
	</ion-menu>

	<ion-router-outlet id="content" main [swipeGesture]="false"></ion-router-outlet>
</ng-template>

<version-release-dialog #dialogRef [isMobile]="true"></version-release-dialog>