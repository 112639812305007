import { IRoomDesignWallModel } from "./room-design-wall.model";

export enum RoomDesignPlatform { Desktop, Mobile }

export interface IRoomDesignModel {
	roomDesignPlatform: RoomDesignPlatform;
	pageOffsetX: number;
	pageOffsetY: number;
	roomDesignBase64: string;

	ceilingHeight: number;
	walls: IRoomDesignWallModel[];
}

export class RoomDesignModel implements IRoomDesignModel {
	constructor(roomDesignPlatform: RoomDesignPlatform) {
		this.roomDesignPlatform = roomDesignPlatform;
		this.walls = [];
	}

	roomDesignPlatform: RoomDesignPlatform;

	pageOffsetX: number;
	pageOffsetY: number;
	roomDesignBase64: string;

	ceilingHeight: number;
	walls: IRoomDesignWallModel[];
}
