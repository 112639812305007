import { IAppointmentResourceModel } from "./appointment-resource.model"
import { IAppointmentLaborCodeModel } from "./appointment-labor-code.model"
import { IAppointmentModel } from "@models/appointments/appointment.model";

export interface IAppointmentSummaryModel {
	appointmentId: number;
	isPending: boolean;
	isTimeBlock: boolean;
	isComplate: boolean;
	jobName: string;
	jobCustomerDisplayName: string;
	jobCity: string;
	startDate: Date;
	endDate: Date;
	duration: number;
	scratchPad: string;
	multiDayDates: Date[];
	resources: IAppointmentResourceModel[];
	laborCodes: IAppointmentLaborCodeModel[];
}

export class AppointmentSummaryModel implements IAppointmentSummaryModel {
	appointmentId: number;
	isPending: boolean;
	isTimeBlock: boolean;
	isComplate: boolean;
	jobName: string;
	jobCustomerDisplayName: string;
	jobCity: string;
	startDate: Date;
	endDate: Date;
	duration: number;
	scratchPad: string;
	multiDayDates: Date[];
	resources: IAppointmentResourceModel[];
	laborCodes: IAppointmentLaborCodeModel[];

	static populateFromAppointmentModel(appointmentModel: IAppointmentModel): IAppointmentSummaryModel {
		const returnModel = new AppointmentSummaryModel();

		returnModel.appointmentId = appointmentModel.appointmentId;
		returnModel.isPending = appointmentModel.isPending;
		returnModel.isTimeBlock = appointmentModel.isTimeBlock;
		returnModel.isComplate = appointmentModel.isComplate;
		returnModel.jobName = appointmentModel.jobName;
		returnModel.jobCustomerDisplayName = appointmentModel.jobCustomerDisplayName;
		returnModel.startDate = appointmentModel.startDate;
		returnModel.endDate = appointmentModel.endDate;
		returnModel.duration = appointmentModel.duration;
		returnModel.scratchPad = appointmentModel.scratchPad;
		returnModel.multiDayDates = appointmentModel.multiDayDates;
		returnModel.multiDayDates = appointmentModel.multiDayDates;
		returnModel.laborCodes = appointmentModel.laborCodes;

		return returnModel;
	}
}

