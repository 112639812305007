import { ISlickFileModel, SlickFileModel } from "slick-components";

export interface IDocumentModel extends ISlickFileModel {
	documentId: number;
	folderUuid: string;
	contentType: string;
	fileExt: string;
	originalFileName: string;
}

export class DocumentModel extends SlickFileModel implements IDocumentModel {
	documentId: number;
	folderUuid: string;
	contentType: string;
	fileExt: string;
	originalFileName: string;
}